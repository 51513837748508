import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Callback = () => {
  const navigate = useNavigate();
  const redirectPath = localStorage.getItem('redirectToAfterAuth') || '/';

  useEffect(() => {
    navigate(redirectPath);
  }, [navigate, redirectPath]);

  return <div>Please wait...</div>;
};

export default Callback;
