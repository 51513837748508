import { useContext, useMemo } from 'react';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { ProcPageParamContext } from '../ProcPage';
import { useParams } from 'react-router-dom';

const miniScopeCardStyle = {
  background: '#fff',
  boxShadow: '0 3px 2px rgba(0, 0, 0, 0.15)',
  borderRadius: '8px',
  marginRight: '10px',
  padding: '18px 32px',
  flexBasis: '60%'
};

const MiniScopeCard = () => {
  const { result } = useContext(ProcPageParamContext);
  const params = useParams();

  const breadcrumbs = useMemo(() => {
    const { entryPointId, subEntryPointId, procId } = params;
    const initBreadcrumbs = ['Org Level', entryPointId];
    if (subEntryPointId) {
      initBreadcrumbs.push(subEntryPointId);
    }
    initBreadcrumbs.push(procId);
    return initBreadcrumbs;
  }, [params]);

  return (
    <Box sx={miniScopeCardStyle}>
      <Typography variant="h2" component="h2" sx={{ fontWeight: 600, fontSize: '30px', lineHeight: '37px' }}>
        {result.procData.name}
      </Typography>
      <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ marginBottom: '12px' }}>
        {breadcrumbs.map((b, index) => (
          <Typography key={index} color="inherit">
            {b}
          </Typography>
        ))}
      </Breadcrumbs>
      <Typography
        variant="h3"
        component="h3"
        sx={{ fontWeight: 600, fontSize: '18px', lineHeight: '22px', marginBottom: '6px' }}
      >
        Description
      </Typography>
      <Box sx={{ fontWeight: 400, fontSize: '14px', lineHeight: '17px' }}>
        A summary of the regulatory touch points(RTPs) for the <em>{result.procData.name}</em> procedure and their
        order. Drill-down from here to view more details on the RTPs.
      </Box>
    </Box>
  );
};

export default MiniScopeCard;
