import { useContext, useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import forbidden from '../../../icons/forbidden.svg';
import { ProcPageParamContext } from '../ProcPage';
import { ProcCountType } from '../../../utils/count-proc-type';

const impactCardStyle = {
  background: '#ffffff',
  boxShadow: '0 3px 2px rgba(0, 0, 0, 0.15)',
  borderRadius: '8px',
  flexBasis: '40%',
  padding: '18px 32px'
};

const ImpactCard = () => {
  const {
    result: { procData }
  } = useContext(ProcPageParamContext);

  const procDataCount = useMemo(() => {
    const procCount: ProcCountType = {
      RTPs: procData.rtps?.length || 0,
      'Business Units': 2
    };
    return procCount;
  }, [procData]);

  return (
    <Box sx={impactCardStyle}>
      <Typography
        variant="h2"
        component="h2"
        sx={{
          fontWeight: 600,
          fontSize: '18px',
          lineHeight: '22px',
          borderBottom: '1px solid #BCBCBC',
          paddingBottom: '10px',
          marginBottom: '27px'
        }}
      >
        Impacts
      </Typography>
      <Box>
        {Object.entries(procDataCount).map((impact, index: number) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              ...(index !== Object.entries(procDataCount).length - 1 && { marginBottom: '32px' })
            }}
          >
            <Box>{impact[0]}</Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', minWidth: 70 }}>
              <img src={forbidden} alt="warn" />
              <Box sx={{ fontWeight: '600', fontSize: '24px', marginLeft: '10px' }}>{impact[1]}</Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ImpactCard;
