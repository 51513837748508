import { useContext, useMemo } from 'react';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { BusinessUnitParamContext } from '../BusinessUnit';
import { Policy } from '../../../interfaces/LandingPage';

const miniScopeCardStyle = {
  background: '#fff',
  boxShadow: '0 3px 2px rgba(0, 0, 0, 0.15)',
  borderRadius: '8px',
  marginRight: '10px',
  padding: '18px 32px',
  flexBasis: '60%'
};

const MiniScopeCard = () => {
  const {
    result: { entryPointId, subEntryPointId, policy, data }
  } = useContext(BusinessUnitParamContext);

  const breadcrumbs = useMemo(() => {
    const initBreadcrumbs = ['Org Level', entryPointId];
    if (subEntryPointId) {
      initBreadcrumbs.push(subEntryPointId);
    }
    return initBreadcrumbs;
  }, [entryPointId, subEntryPointId]);

  const policyData: Policy | undefined = useMemo(() => {
    if (policy) {
      const framework = data.impacts.framework.find((f) => f.name === entryPointId);
      return framework?.policies.find((p) => p.name === subEntryPointId);
    }
    return undefined;
  }, [data.impacts.framework, entryPointId, policy, subEntryPointId]);

  return (
    <Box sx={miniScopeCardStyle}>
      <Typography variant="h2" component="h2" sx={{ fontWeight: 600, fontSize: '30px', lineHeight: '37px' }}>
        {subEntryPointId ? subEntryPointId : entryPointId}
      </Typography>
      <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ marginBottom: '12px' }}>
        {breadcrumbs.map((b, index) => (
          <Typography key={index} color="inherit">
            {b}
          </Typography>
        ))}
      </Breadcrumbs>
      {policyData && (
        <Box sx={{ marginBottom: '5px', display: 'flex' }}>
          <Box sx={{ width: 120, fontWeight: '600', fontSize: '12px', lineHeight: '19px' }}>
            <Box>Type</Box>
            <Box>Regulator</Box>
          </Box>
          <Box sx={{ fontSize: '12px', lineHeight: '19px' }}>
            <Box>Framework</Box>
            <Box>{policyData.regulator}</Box>
          </Box>
        </Box>
      )}
      <Typography
        variant="h3"
        component="h3"
        sx={{ fontWeight: 600, fontSize: '18px', lineHeight: '22px', marginBottom: '6px' }}
      >
        Description
      </Typography>
      <Box sx={{ fontWeight: 400, fontSize: '14px', lineHeight: '17px' }}>
        Details on processes and procedures for the <em>{subEntryPointId ? subEntryPointId : entryPointId}</em> business
        unit. From this screen you can explore any sub-business units and drill-down into further detail.
      </Box>
    </Box>
  );
};

export default MiniScopeCard;
