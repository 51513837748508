import { BasicPolicyDataStructure, Policy } from '../interfaces/LandingPage';

export interface RtpCountType {
  Procedures: number;
  Processes: number;
  RTPs: number;
}

export const countRtps = (data: BasicPolicyDataStructure | Policy): RtpCountType => {
  const result = {
    Procedures: 0,
    Processes: 0,
    RTPs: 0
  };

  data.procs.forEach((proc) => {
    if (proc.type === 'Procedure') {
      result.Procedures++;
    }
    if (proc.type === 'Process') {
      result.Processes++;
    }
    result.RTPs += proc.rtps?.length || 0;
  });
  return result;
};
