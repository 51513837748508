import { useEffect, useState } from 'react';
import { Card, Typography } from '@mui/material';
import { Reference } from '../../interfaces/Graph';
import { graphService } from '../../services/graph-service';
import ReferenceInput from './ReferenceInput';

interface UserInputBoardProps {
  rtpEntryPoint: string;
}

const UserInputBoard = ({ rtpEntryPoint }: UserInputBoardProps) => {
  const [references, setReferences] = useState<Array<Reference>>([]);

  useEffect(() => {
    const init = async () => {
      try {
        const refs = await graphService.getRtpReferences({ rtpName: rtpEntryPoint });
        setReferences(refs);
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, [rtpEntryPoint]);

  return (
    <Card
      sx={{
        padding: '10px 32px',
        backgroundColor: '#ffffff',
        my: 1
      }}
    >
      <Typography variant="h2" mb={2}>
        Reasoner Input
      </Typography>
      {references.map((ref, index) => {
        return <ReferenceInput reference={ref} key={ref.elementId} prefix={`${index + 1}`} />;
      })}
    </Card>
  );
};

export default UserInputBoard;
