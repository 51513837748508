import { createContext, useEffect, useState } from 'react';
import { DataModelParaStructure, Test } from '../interfaces/Rtp';
import DescriptionCard from './description_card/DescriptionCard';
import ImpactCard from './otherImpactCard/ImpactCard';
import UserInputBoard from './userInput/UserInputBoard';
import TestCaseBoard from './testCaseBoard';
import axios from 'axios';
import { RESULT_URL } from '../utils/constants';
import { Box } from '@mui/material';
import { graphService } from '../services/graph-service';

interface ResultStructure {
  [key: string]: string;
}

interface ContextStructure {
  result: ResultStructure;
  setResult?: (res: ResultStructure) => void;
  findRef?: (refId: number) => DataModelParaStructure | null;
}

export const ParamContext = createContext<ContextStructure>({
  result: {}
});

const RtpBoard = ({ rtpEntryPoint }: { rtpEntryPoint: string }) => {
  const [result, setResult] = useState<ResultStructure>({});
  const [predication, setPredication] = useState<string>('No Result');
  const [details, setDetails] = useState<object>();
  const [tests, setTests] = useState<Test[]>([]);

  useEffect(() => {
    const init = async () => {
      const testResult = await graphService.getTestsByStartNode(rtpEntryPoint);
      setTests(testResult);
    };

    init();
  }, [rtpEntryPoint]);

  const getResult = (event: React.MouseEvent<HTMLElement>) => {
    console.log(JSON.stringify(result, null, 2));

    const filteredResult: ResultStructure = Object.fromEntries(
      Object.entries(result).filter(([_, value]) => value !== '')
    );

    axios
      .post(RESULT_URL + rtpEntryPoint, { ...filteredResult })
      .then((data) => {
        if (data.data) {
          setPredication(data.data);
        } else {
          setPredication('Invalid Response');
        }
      })
      .catch((e) => {
        console.error(e);
        setPredication('Inconclusive');
      });

    axios
      .post(RESULT_URL + rtpEntryPoint + '/details', { ...filteredResult })
      .then((data) => {
        if (data.data) {
          setDetails(data.data);
        } else {
          setDetails(data);
        }
      })
      .catch((e) => {
        console.error(e);
        setDetails(e);
      });
  };

  const findRef = (refId: number) => {
    let breadthFirstSearchArray: Array<DataModelParaStructure> = [];
    // rtp.dataModel.parameters.forEach((kid) => breadthFirstSearchArray.push(kid));
    while (breadthFirstSearchArray.length > 0) {
      const firstElement = breadthFirstSearchArray.shift() as DataModelParaStructure;
      if (firstElement['$id'] === refId) {
        return firstElement;
      } else {
        firstElement?.children?.forEach((kid) => breadthFirstSearchArray.push(kid));
      }
    }

    return null;
  };

  return (
    <Box>
      <Box display="flex" boxShadow="0px 3px 2px 0px rgba(0, 0, 0, 0.15)" borderRadius="8px">
        <DescriptionCard rtpEntryPoint={rtpEntryPoint} />
        <ImpactCard rtpEntryPoint={rtpEntryPoint} />
      </Box>
      <div>
        <ParamContext.Provider value={{ result, setResult, findRef }}>
          <UserInputBoard rtpEntryPoint={rtpEntryPoint} />
        </ParamContext.Provider>
        <TestCaseBoard getResult={getResult} conclusion={predication.toString()} details={details} tests={tests} />
      </div>
    </Box>
  );
};

export default RtpBoard;
