import { useEffect, useMemo, useState } from 'react';
import { PossibleConclusion, Conclusion, Summary } from '../../interfaces/Rtp';
import styles from './style.module.css';
import ShortContentItem from './ShortContentItem';
import ResultComponent from '../resultComponent';
import { Box, CircularProgress } from '@mui/material';
import { graphService } from '../../services/graph-service';
import { useRtp } from '../Rtp';

export const getColor = (conclusion: PossibleConclusion) => {
  switch (conclusion) {
    case 'Permitted':
      return styles.green;
    case 'Is':
      return styles.green;
    case 'Obligatory':
      return styles.yellow;
    case 'Excused':
      return styles.yellow;
    case 'IsNot':
      return styles.red;
    case 'Forbidden':
      return styles.red;
    default:
      return styles.red;
  }
};

const DescriptionCard = ({ rtpEntryPoint }: { rtpEntryPoint: string }) => {
  const { rtps } = useRtp();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [conclusions, setConclusions] = useState<Conclusion[]>([]);
  const [summaries, setSummaries] = useState<Summary[]>([]);

  useEffect(() => {
    const init = async () => {
      setIsLoading(false);
      setConclusions(await graphService.getRtpConclusions(rtpEntryPoint));
      setSummaries(await graphService.getRtpSummary(rtpEntryPoint));
      setIsLoading(false);
    };

    init();
  }, [rtpEntryPoint]);

  const selectedRtp = useMemo(() => rtps.find((rtp) => rtp.name === rtpEntryPoint), [rtpEntryPoint, rtps]);

  if (isLoading) {
    return (
      <Box width="100%" height="400px" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Box>
    );
  }

  return (
    <div className={styles.descriptionCard}>
      <div className={styles.leftCard}>
        <h1>Regulatory Touch Point</h1>
        <div className={styles.cardSnippet}>
          <h3>Description</h3>
          <div className={styles.cardContent}>{selectedRtp?.comment}</div>
        </div>

        <div className={styles.cardSnippet}>
          <div className={styles.cardHeader}>Source</div>
          {summaries.map((summary, index) => (
            <ShortContentItem key={index} summary={summary} />
          ))}
        </div>
      </div>
      <div className={styles.rightCard}>
        <div className={styles.possibleResults}>Possible Results</div>
        <div className={styles.conclusionRow}>
          {conclusions.map((conclusion, index) => {
            return <ResultComponent conclusion={conclusion.labels?.[1]} key={index} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default DescriptionCard;
