import * as THREE from 'three';
import { Hierarchy } from '../interfaces/Graph';

export type GraphViewElement = {
  name: string;
  id: number | string;
  label: string;
};

export type GraphViewLink = {
  source: number | string;
  target: number | string;
  label: string;
};

export const DOCUMENT_COLOR = '#C990C0';
export const SECTION_COLOR = '#f16667';
export const V_SECTION_COLOR = '#8DCC93';
export const SELECTED_NODE = '#ff0000';
export const V_DEFINES_COLOR = '#F79767';
export const V_HAS_SECTIONS_COLOR = '#ffffff';
export const INT_REF_COLOR = '#98FB98';
export const EXT_REF_COLOR = '#FA8072';
export const DEFAULT_COLOR = '#2568b4';

const getNodeColor = (graphViewElement: GraphViewElement, rtpEntryPoint: string) => {
  if (graphViewElement.name === rtpEntryPoint) return SELECTED_NODE;
  switch (graphViewElement.label) {
    case 'Document':
      return DOCUMENT_COLOR;
    case 'Section':
      return SECTION_COLOR;
    case 'vSection':
      return V_SECTION_COLOR;
    case 'vDEFINES':
      return V_DEFINES_COLOR;
    case 'vHAS_SECTIONS':
      return V_HAS_SECTIONS_COLOR;
    case 'INT_REF_DIRECT':
      return INT_REF_COLOR;
    default:
      return DEFAULT_COLOR;
  }
};

export const getNodeLabel = (graphViewElement: GraphViewElement) => {
  if (graphViewElement.name !== null) return '<strong style="color: black">' + graphViewElement.name + '</strong>';
  return '';
};

export const getLinkLabel = (graphViewLink: GraphViewLink) => {
  if (graphViewLink.label !== null) return '<strong style="color: black">' + graphViewLink.label + '</strong>';
  return '';
};

export const getLinkColor = (GraphViewLink: GraphViewLink) => {
  switch (GraphViewLink.label) {
    case 'INT_REF_DIRECT':
      return INT_REF_COLOR;
    case 'EXT_REF_DIRECT':
      return EXT_REF_COLOR;
    default:
      return V_HAS_SECTIONS_COLOR;
  }
};

export const getNodeThreeObject = (graphViewElement: GraphViewElement, rtpEntryPoint: string) => {
  let elementShape;

  if (graphViewElement.name === rtpEntryPoint) {
    elementShape = new THREE.OctahedronGeometry(12);
  } else {
    elementShape = new THREE.SphereGeometry(10);
  }

  var threeNode = new THREE.Mesh(
    elementShape,
    new THREE.MeshLambertMaterial({
      color: getNodeColor(graphViewElement, rtpEntryPoint),
      transparent: true,
      opacity: 1
    })
  );

  return threeNode;
};

export const mapHierarchyToGraphData = (
  hierarchy: Hierarchy
): {
  nodes: GraphViewElement[];
  links: GraphViewLink[];
} => ({
  nodes: hierarchy.nodes.map((node) => ({
    id: node.elementId,
    label: node.label || '',
    name: node.name || node.fullId || node.displayName || ''
  })),
  links: hierarchy.links.map((link) => ({
    source: link.startNodeElementId,
    target: link.endNodeElementId,
    label: link.label || ''
  }))
});
