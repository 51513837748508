import { useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import Graph from '../otherImpactCard/Graph';

const RtpFrame = ({ rtpEntryPoint }: { rtpEntryPoint: string }) => {
  const [searchParams] = useSearchParams();

  return (
    <Box sx={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, bgcolor: 'white' }}>
      <Graph
        rtpEntryPoint={rtpEntryPoint}
        isUpstream={searchParams.get('view') !== 'downstream'}
        sx={{ width: '100%', height: '100%' }}
        enableClick={false}
      />
    </Box>
  );
};

export default RtpFrame;
