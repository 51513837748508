import styles from './style.module.css';
import ResultComponent from '../resultComponent';
import { JsonViewer } from '@textea/json-viewer';
import TestCaseItem from './testCaseItem';
import { Test } from '../../interfaces/Rtp';

interface TestCaseBoardProps {
  getResult: (event: React.MouseEvent<HTMLElement>) => void;
  conclusion: string;
  details: object | undefined;
  tests: Test[];
}
const TestCaseBoard = (props: TestCaseBoardProps) => {
  const { getResult } = props;

  return (
    <div className={styles.testCaseBoard}>
      {props.tests.length > 0 && (
        <div className={styles.board}>
          <div className={styles.boardHeader}>
            <span className={styles.header}>Test Cases</span>
            {/* <span className={styles.expectedResult}>Expected Result</span>
            <span>Import Test Case</span> */}
          </div>
          {props.tests.map((test) => (
            <TestCaseItem key={test.uuid} test={test} />
          ))}
        </div>
      )}
      <div className={styles.userInput_header}>
        <div className={styles.importButtonContainer}>
          <ResultComponent conclusion={props.conclusion} />
          <button
            className={styles.runTestCase}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              getResult(e);
            }}
          >
            Get Reasoning Result
          </button>
        </div>
      </div>
      <div className={styles.board}>{props.details && <JsonViewer value={props.details} />}</div>
    </div>
  );
};

export default TestCaseBoard;
