import { createContext, useContext, useEffect, useState } from 'react';
import RtpBoard from './RtpBoard';
import styles from './Rtp.module.css';
import AppLayout from './Layouts/AppLayout';
import RTPSelector from './RtpSelector/RtpSelector';
import { Box } from '@mui/material';
import { Rtp } from '../interfaces/Graph';
import { useSearchParams } from 'react-router-dom';

const RtpContext = createContext<{ rtps: Rtp[] }>({
  rtps: []
});

export const useRtp = () => {
  return useContext(RtpContext);
};

const RtpComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [rtps, setRtps] = useState<Rtp[]>([]);

  function handleSelectChange(value: string) {
    setSearchParams({ ...searchParams, entryPoint: value });
  }

  const entryPointId = searchParams.get('entryPoint');

  useEffect(() => {
    document.title = `Realta Logic Regulatory Dashboard - ${entryPointId}`;
  }, [entryPointId]);

  return (
    <RtpContext.Provider value={{ rtps }}>
      <AppLayout>
        <Box className={styles.mainBoard}>
          <RTPSelector entrypoint={entryPointId || ''} onChange={handleSelectChange} onLoadRtps={setRtps} />
          {entryPointId && <RtpBoard rtpEntryPoint={entryPointId} />}
        </Box>
      </AppLayout>
    </RtpContext.Provider>
  );
};

export default RtpComponent;
