import { gql } from '@apollo/client';
import { apollo } from '../apollo/client';
import { Hierarchy, Reference, Rtp } from '../interfaces/Graph';
import { Conclusion, Section, Summary, Test } from '../interfaces/Rtp';

const GET_RTP_HIERARCHY = gql`
  query ($rtpName: String!, $depth: Float!, $upstream: Boolean) {
    getRtpHierarchy(rtpName: $rtpName, depth: $depth, upstream: $upstream) {
      nodes {
        elementId
        uuid
        name
        comment
        displayName
        externalId
        shortName
        label
        fullId
        type
      }
      links {
        elementId
        label
        EndOffset
        BeginOffset
        Score
        Text
        startNodeElementId
        endNodeElementId
      }
    }
  }
`;
const getRtpHierarchy = async ({
  rtpName,
  upstream,
  depth
}: {
  rtpName: string;
  upstream: boolean;
  depth: number;
}): Promise<Hierarchy> => {
  const { data } = await apollo.mutate({
    mutation: GET_RTP_HIERARCHY,
    variables: {
      rtpName,
      upstream,
      depth
    }
  });
  return data?.getRtpHierarchy;
};

const GET_RTP_REFERENCES = gql`
  query ($rtpName: String!) {
    getRtpReferences(rtpName: $rtpName) {
      elementId
      name
      comment
      label
      type
    }
  }
`;
const getRtpReferences = async ({ rtpName }: { rtpName: string }): Promise<Reference[]> => {
  const { data } = await apollo.mutate({
    mutation: GET_RTP_REFERENCES,
    variables: {
      rtpName
    }
  });
  return data?.getRtpReferences;
};

const GET_RTPS = gql`
  query ($roles: [String!]!) {
    getRtps(roles: $roles) {
      name
      comment
      type
    }
  }
`;
const getRtps = async (roles: string[]): Promise<Rtp[]> => {
  const { data } = await apollo.mutate({
    mutation: GET_RTPS,
    variables: {
      roles
    }
  });
  return data?.getRtps;
};

const GET_RTP_CONCLUSIONS = gql`
  query ($rtpName: String!) {
    getRtpConclusions(rtpName: $rtpName) {
      name
      type
      labels
    }
  }
`;
const getRtpConclusions = async (rtpName: string): Promise<Conclusion[]> => {
  const { data } = await apollo.mutate({
    mutation: GET_RTP_CONCLUSIONS,
    variables: {
      rtpName
    }
  });
  return data?.getRtpConclusions;
};

const GET_RTP_SUMMARY = gql`
  query ($rtpName: String!) {
    getRtpSummary(rtpName: $rtpName) {
      rtp {
        name
        comment
        type
        elementId
      }
      document {
        uuid
        shortName
        displayName
      }
      sections {
        elementId
        uuid
        id
        fullId
        startBoundryY
        endBoundryY
        startBoundryPage
        endBoundryPage
      }
    }
  }
`;
const getRtpSummary = async (rtpName: string): Promise<Summary[]> => {
  const { data } = await apollo.mutate({
    mutation: GET_RTP_SUMMARY,
    variables: {
      rtpName
    }
  });
  return data?.getRtpSummary;
};

const GET_SECTIONS_BY_PAGE_QUERY = gql`
  query GET_SECTIONS_BY_PAGE_QUERY($documentUuid: String!, $page: Float!) {
    getDocumentSectionsByPage(documentUuid: $documentUuid, page: $page) {
      uuid
      id
      fullId
      startBoundryY
      endBoundryY
      startBoundryPage
      endBoundryPage
    }
  }
`;
const getDocumentSectionsByPage = async ({
  documentUuid,
  page
}: {
  documentUuid: string;
  page: number;
}): Promise<Section[]> => {
  const { data } = await apollo.mutate({
    mutation: GET_SECTIONS_BY_PAGE_QUERY,
    variables: {
      documentUuid,
      page
    }
  });
  return (data?.getDocumentSectionsByPage as Section[]) || [];
};

const GET_TESTS_BY_START_NODE_QUERY = gql`
  query GET_TESTS_BY_START_NODE($startNode: String!) {
    getTestsByStartNode(startNode: $startNode) {
      uuid
      name
      startNode
      fact
      result
      description
      status
      requiredResults
      unwantedResults
      sectionUuid
      documentUuid
      page
    }
  }
`;
const getTestsByStartNode = async (startNode: string): Promise<Test[]> => {
  const { data } = await apollo.mutate({
    mutation: GET_TESTS_BY_START_NODE_QUERY,
    variables: {
      startNode
    }
  });
  return (data?.getTestsByStartNode as Test[]) || [];
};

export const graphService = {
  getRtps,
  getRtpHierarchy,
  getRtpReferences,
  getRtpConclusions,
  getRtpSummary,
  getDocumentSectionsByPage,
  getTestsByStartNode
};
