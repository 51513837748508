import { BrowserCacheLocation, PublicClientApplication } from '@azure/msal-browser';
import { MSAL_AUTHORITY, MSAL_CALLBACK, MSAL_CLIENT_ID } from './constants';

const msalConfig = {
  auth: {
    clientId: MSAL_CLIENT_ID,
    authority: MSAL_AUTHORITY,
    redirectUri: MSAL_CALLBACK
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: true
  },
  knownAuthorities: ['localhost']
};

const msalInstance = new PublicClientApplication(msalConfig);

export const getMsalInstance = () => {
  return msalInstance;
};
