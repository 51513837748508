import { BasicPolicyDataStructure } from '../interfaces/LandingPage';

export interface RtpBusinessUnitCountType {
  'Business Units': number;
  'Procedures/Processes': number;
  RTPs: number;
}

export const countRtpBusinessUnit = (data: BasicPolicyDataStructure): RtpBusinessUnitCountType => {
  const result = {
    'Business Units': 0,
    'Procedures/Processes': 0,
    RTPs: 0
  };

  result['Business Units'] = data.children.length;
  data.procs.forEach((proc) => {
    result['Procedures/Processes'] += 1;
    result.RTPs += proc.rtps?.length || 0;
  });
  return result;
};
