import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { MSAL_CALLBACK } from '../../utils/constants';
import { getMsalInstance } from '../../utils/get-msal-instance';
import { useEffect, useState } from 'react';
import { AccountInfo } from '@azure/msal-browser';

const Header = () => {
  // const sections = [{ name: 'scoping' }, { name: 'implementation' }, { name: 'monitoring' }];
  // const breadcrumbs = [
  //   { name: 'Org Level', url: '/' },
  //   { name: 'Business unit', url: '/business-unit' },
  //   { name: 'Process/Procedure', url: '/' },
  //   { name: 'RTPs', url: '/rtp' }
  // ];
  const msalInstance = getMsalInstance();

  const [account, setAccount] = useState<AccountInfo | null>(msalInstance.getActiveAccount());

  useEffect(() => {
    const init = async () => {
      await msalInstance.initialize();

      // function getCookie(name: string) {
      //   const value = '; ' + document.cookie;
      //   const parts = value.split('; ' + name + '=');
      //   if (parts.length === 2) return parts?.pop()?.split(';').shift();
      //   return '';
      // }
      // if (getCookie('ssoAccount')) {
      //   const response = await msalInstance.ssoSilent({
      //     scopes: ['User.Read'],
      //     loginHint: getCookie('ssoAccount')
      //   });
      //   setAccount(response.account);
      // }
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = async () => {
    localStorage.setItem('redirectToAfterAuth', location.pathname);
    msalInstance
      .loginPopup({
        redirectUri: MSAL_CALLBACK,
        scopes: ['user.read']
      })
      .then((response) => {
        msalInstance.setActiveAccount(response.account);
        setAccount(response.account);
        document.cookie = `ssoAccount=${response.account.username}; path=/; domain=localhost;`;
      });
  };

  const handleLogout = () => {
    localStorage.setItem('redirectToAfterAuth', location.pathname);
    msalInstance.logoutPopup();
    setAccount(null);
  };

  return (
    <Box
      sx={{
        background: '#fff',
        boxShadow: '0 3px 2px rgba(0, 0, 0, 0.15)',
        borderRadius: '8px',
        padding: '32px',
        display: 'flex',
        justifyContent: 'end',
        marginBottom: '17px'
      }}
    >
      {account ? (
        <Box display="flex" flexDirection="column" justifyContent="end" gap={1}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>{account.name}</Box>
          <Button onClick={handleLogout} variant="contained" color="inherit">
            Logout
          </Button>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Button variant="contained" color="inherit" onClick={handleLogin}>
            Login
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Header;
