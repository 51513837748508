export const API_PREFIX = process.env.API_PREFIX || 'https://kggraph-ingestiondb.azurewebsites.net/api/';
export const VIZ_PREFIX =
  process.env.VIZ_PREFIX || 'https://mango-mushroom-0d922ec00-10.eastasia.1.azurestaticapps.net';

export const RESULT_URL = process.env.RESULT_URL || `${API_PREFIX}Reasoning/conclusion/`;
export const SCOPE_URL = process.env.SCOPE_URL || `${API_PREFIX}Explore/scope/`;

export const DEFAULT_SCOPE_ID = 'ddo';
export const DEFAULT_ORG_ID = 'rlbank';
export const COUNT_CARD_PARTS = ['Obligations Register', 'Business Units', 'Products', 'Policies'];

export const GRAPHQL_HTTP_ENDPOINT =
  process.env.GRAPHQL_HTTP_ENDPOINT || 'https://app-authtool-server-staging.azurewebsites.net/graphql';

export const MSAL_CLIENT_ID = process.env.MSAL_CLIENT_ID || '192a39a0-2a62-4a1a-bf58-ca14a68cccb9';
export const MSAL_AUTHORITY =
  process.env.MSAL_AUTHORITY || 'https://login.microsoftonline.com/fab4f66c-4623-4e56-a921-ea3c26b94a9b';
export const MSAL_CALLBACK = process.env.MSAL_CALLBACK || 'https://rtpd.realtalogic.com/callback';

export const AUTH_TOOL_ENDPOINT = process.env.AUTH_TOOL_ENDPOINT || 'https://docui.realtalogic.com';
