import Rtp from './components/Rtp';
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import BusinessUnit from './components/BusinessUnit/BusinessUnit';
import NotFoundPage from './components/NotFoundPage/NotFoundPage';
import ProcPage from './components/ProcPage/ProcPage';
import Callback from './components/Callback/Callback';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        {/* <Route path="/" element={<LandingPage />} /> */}
        {/* temporary make rtp os the landing page */}
        <Route path="/" element={<Rtp />} />
        <Route path="/rtp" element={<Rtp />} />
        <Route path="/rtp/:entryPointId" element={<Rtp />} />
        <Route path="/business-unit" element={<BusinessUnit />} />
        <Route path="/business-unit/:entryPointId" element={<BusinessUnit />} />
        <Route path="/business-unit/:entryPointId/:subEntryPointId" element={<BusinessUnit />} />
        <Route path="/procs/:entryPointId/:subEntryPointId/:procId" element={<ProcPage />} />
        <Route path="/procs/:entryPointId/:procId" element={<ProcPage />} />
        <Route path="/callback" element={<Callback />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
