import { Test } from '../../../interfaces/Rtp';
import { AUTH_TOOL_ENDPOINT } from '../../../utils/constants';
import styles from './style.module.css';

interface testCaseItemProps {
  test: Test;
}

const TestCaseItem = ({ test }: testCaseItemProps) => {
  return (
    <div className={styles.testCaseItem}>
      <div className={styles.description}>{test.name}</div>
      <a
        href={`${AUTH_TOOL_ENDPOINT}/documents?document-id=${test.documentUuid}&section-id=${test.sectionUuid}&page=${test.page}&view=Test`}
        target="_blank"
        rel="noreferrer"
        className={styles.buttonBasic}
      >
        Source
      </a>
      {/* <div className={styles.resultComponent}>
        <ResultComponent conclusion={props.conclusion} />
      </div>
      <div className={styles.radioDiv}>
        <input className={styles.radioButton} type="radio" />
      </div> */}
    </div>
  );
};

export default TestCaseItem;
