import styles from './style.module.css';
import { Summary } from '../../interfaces/Rtp';
import { useMemo } from 'react';
import { AUTH_TOOL_ENDPOINT } from '../../utils/constants';

interface ShortContentItemProps {
  dotted?: boolean;
  summary: Summary;
}

const ShortContentItem = ({ dotted, summary }: ShortContentItemProps) => {
  const focusSection = useMemo(() => summary.sections[summary.sections.length - 1], [summary.sections]);

  return (
    <div className={dotted ? styles.itemGroupDotted : styles.itemGroup}>
      <div className={styles.descriptionContent}>
        {[
          summary.document.displayName,
          summary.sections
            .map((section) => section.fullId)
            .filter(Boolean)
            .join(', ')
        ].join(', ')}
      </div>
      <a
        href={`${AUTH_TOOL_ENDPOINT}/documents?document-id=${summary.document.uuid}&section-id=${focusSection.uuid}&page=${focusSection.startBoundryPage}&view=References`}
        target="_blank"
        rel="noreferrer"
        className={styles.buttonBasic}
      >
        Source
      </a>
    </div>
  );
};

export default ShortContentItem;
